import { Html } from '@react-email/html';
import { Text } from '@react-email/text';

const TalentEmail = () => {
  return (
    <Html>
      <Text>Hey there!</Text>
      <Text>
        We’re so excited to have you among our Top Personas. We’re working hard to respond to all the requests, so it
        may take up to 1-3 business days to get back to you.
      </Text>
      <Text>Talent Relations Team</Text>
    </Html>
  );
};

export default TalentEmail;
