import { message } from 'antd';
import axios, { AxiosError } from 'axios';

export const useContactForm = () => {
  const sendUserEmail = async (email: string, subject: string, text: string) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('text', text);
    await axios.post('https://dev.persona.pixcraft.art/api/tool/sendPromoEmailHello', formData, {
      auth: {
        username: 'persona',
        password: 'vDK9YFc8s9ZLgpMY'
      }
    });
  };

  const sendCreatorEmail = async (email: string, subject: string, text: string) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('text', text);
    await axios.post('https://dev.persona.pixcraft.art/api/tool/sendPromoEmailTalent', formData, {
      auth: {
        username: 'persona',
        password: 'vDK9YFc8s9ZLgpMY'
      }
    });
  };

  const sendCreatorInfo = async (email: string, contacts: string) => {
    try {
      await axios.get(
        // eslint-disable-next-line max-len
        'https://docs.google.com/forms/d/e/1FAIpQLSfzUD66XZ5G-jNaTtbaYHpIY_SlIv00EFoAnPq1m5zd1Cxb8w/formResponse?&submit=Submit',
        { params: { usp: 'pp_url', 'entry.2019461240': email, 'entry.106474154': contacts } }
      );
      message.success('Contact info successfully saved');
    } catch (e) {
      const response = (e as AxiosError).response;
      if (response) {
        message.error('Something went wrong');
      } else {
        message.success('Contact info successfully saved');
      }
    }
  };

  const sendUserInfo = async (email: string, suggestions: string) => {
    try {
      await axios.get(
        // eslint-disable-next-line max-len
        'https://docs.google.com/forms/d/e/1FAIpQLScNKhjw7UaO7osywEFW-D72qvwUS06wMKNe75guEcDzCESUhA/formResponse?&submit=Submit',
        { params: { usp: 'pp_url', 'entry.1593959398': email, 'entry.939252552': suggestions } }
      );
      message.success('Contact info successfully saved');
    } catch (e) {
      const response = (e as AxiosError).response;
      if (response) {
        message.error('Something went wrong');
      } else {
        message.success('Contact info successfully saved');
      }
    }
  };

  return {
    sendCreatorInfo,
    sendUserInfo,
    sendUserEmail,
    sendCreatorEmail
  };
};
