import React, { useState } from 'react';
import { Button, Form, Input, Modal, Steps, Typography } from 'antd';
import { FireOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface Props {
  open: boolean;
  loading: boolean;
  onSubmit: (email: string, contacts: string) => Promise<void>;
  onCancel: () => void;
}

const CreatorContactsModal: React.FC<Props> = (props) => {
  const { open, loading, onSubmit, onCancel } = props;
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [email, setEmail] = useState('');

  const steps = [
    {
      key: 0,
      content: (
        <>
          <Title level={4} style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
            WOW! Join the AI future as a Top Persona! Drop your email to continue the journey. 🤩
          </Title>
          <Form.Item initialValue={email} name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
            <Input />
          </Form.Item>
        </>
      )
    },
    {
      key: 1,
      content: (
        <>
          <Title level={4} style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
            Where is your largest following?
            <br />
            Drop us the link. 🔗
          </Title>
          <Form.Item
            name="tiktok"
            label="TikTok"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value ||
                    getFieldValue('twitch') ||
                    getFieldValue('snapchat') ||
                    getFieldValue('instagram') ||
                    getFieldValue('youtube') ||
                    getFieldValue('other')
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please enter at least one social network account'));
                }
              })
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="twitch"
            label="Twitch"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value ||
                    getFieldValue('tiktok') ||
                    getFieldValue('snapchat') ||
                    getFieldValue('instagram') ||
                    getFieldValue('youtube') ||
                    getFieldValue('other')
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please enter at least one social network account'));
                }
              })
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="snapchat"
            label="Snapchat"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value ||
                    getFieldValue('tiktok') ||
                    getFieldValue('twitch') ||
                    getFieldValue('instagram') ||
                    getFieldValue('youtube') ||
                    getFieldValue('other')
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please enter at least one social network account'));
                }
              })
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="instagram"
            label="Instagram"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value ||
                    getFieldValue('tiktok') ||
                    getFieldValue('twitch') ||
                    getFieldValue('snapchat') ||
                    getFieldValue('youtube') ||
                    getFieldValue('other')
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please enter at least one social network account'));
                }
              })
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="youtube"
            label="Youtube"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value ||
                    getFieldValue('tiktok') ||
                    getFieldValue('twitch') ||
                    getFieldValue('snapchat') ||
                    getFieldValue('instagram') ||
                    getFieldValue('other')
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please enter at least one social network account'));
                }
              })
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="other"
            label="Other"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value ||
                    getFieldValue('tiktok') ||
                    getFieldValue('twitch') ||
                    getFieldValue('snapchat') ||
                    getFieldValue('instagram') ||
                    getFieldValue('youtube')
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please enter at least one social network account'));
                }
              })
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </>
      )
    },
    {
      key: 2,
      icon: <FireOutlined />,
      content: (
        <Title level={4} style={{ textAlign: 'center' }}>
          Awesome! Check your email - we’ll get in touch with you ASAP! 🎉
        </Title>
      )
    }
  ];

  const next = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch(() => {
        return;
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCancel = () => {
    form.resetFields();
    setCurrent(0);
    setEmail('');
    onCancel();
  };

  const onFinish = (values: any) => {
    if (current === 0) {
      setEmail(values.email);
      setCurrent(current + 1);
    } else {
      onSubmit(
        email,
        `${values.tiktok ? `TikTok: ${values.tiktok}\n` : ''}${values.twitch ? `Twitch: ${values.twitch}\n` : ''}${
          values.snapchat ? `Snapchat: ${values.snapchat}\n` : ''
        }${values.instagram ? `Instagram: ${values.instagram}\n` : ''}${
          values.youtube ? `Youtube: ${values.youtube}\n` : ''
        }${values.other || ''}`
      ).then(() => setCurrent(current + 1));
    }
  };

  return (
    <Modal
      centered
      title={<div style={{ height: 24 }} />}
      open={open}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={current !== 1 ? handleCancel : prev}>
          {current === 0 ? 'Cancel' : current === 1 ? 'Back' : 'Done'}
        </Button>,
        current < steps.length - 1 ? (
          <Button key="submit" type="primary" loading={loading} onClick={next}>
            {current === 0 ? 'Next' : 'Last step'}
          </Button>
        ) : (
          <div key="empty" />
        )
      ]}
    >
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} requiredMark={false} form={form} onFinish={onFinish}>
        <Steps direction="horizontal" responsive={false} current={current} items={steps} />
        <div>{steps[current].content}</div>
      </Form>
    </Modal>
  );
};

export default CreatorContactsModal;
