import React, { useState } from 'react';
import { Button, Form, Input, Modal, Steps, Typography } from 'antd';
import { FireOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface Props {
  open: boolean;
  loading: boolean;
  onSubmit: (email: string, suggestions: string) => Promise<void>;
  onCancel: () => void;
}

const UserContactsModal: React.FC<Props> = (props) => {
  const { open, loading, onSubmit, onCancel } = props;
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [email, setEmail] = useState('');

  const steps = [
    {
      key: 0,
      content: (
        <>
          <Title level={4} style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
            Hey there, drop your email so we can keep you in the loop on the release date. 😉
          </Title>
          <Form.Item initialValue={email} name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
            <Input />
          </Form.Item>
        </>
      )
    },
    {
      key: 1,
      content: (
        <>
          <Title level={4} style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
            What famous person would you like to chat with? We will ask them to join us! 😍
          </Title>
          <Form.Item name="suggestions">
            <Input.TextArea rows={4} />
          </Form.Item>
        </>
      )
    },
    {
      key: 2,
      icon: <FireOutlined />,
      content: (
        <Title level={4} style={{ textAlign: 'center' }}>
          Awesome! You will get early access as soon as it's good to go. Stay tuned! 🎉
        </Title>
      )
    }
  ];

  const next = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch(() => {
        return;
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCancel = () => {
    form.resetFields();
    setCurrent(0);
    setEmail('');
    onCancel();
  };

  const onFinish = (values: any) => {
    if (current === 0) {
      setEmail(values.email);
      setCurrent(current + 1);
    } else {
      onSubmit(email, values.suggestions).then(() => setCurrent(current + 1));
    }
  };

  return (
    <Modal
      centered
      title={<div style={{ height: 24 }} />}
      open={open}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={current !== 1 ? handleCancel : prev}>
          {current === 0 ? 'Cancel' : current === 1 ? 'Back' : 'Done'}
        </Button>,
        current < steps.length - 1 ? (
          <Button key="submit" type="primary" loading={loading} onClick={next}>
            {current === 0 ? 'Next' : 'Send'}
          </Button>
        ) : (
          <div key="empty" />
        )
      ]}
    >
      <Form requiredMark={false} form={form} onFinish={onFinish}>
        <Steps direction="horizontal" responsive={false} current={current} items={steps} />
        <div>{steps[current].content}</div>
      </Form>
    </Modal>
  );
};

export default UserContactsModal;
