import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from '../components/App';
import Landing from '../components/landing/Landing';
import NoMatch from './NoMatch';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Landing />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
