import { Html } from '@react-email/html';
import { Text } from '@react-email/text';

const FanEmail = () => {
  return (
    <Html>
      <Text>Hey there!</Text>
      <Text>
        We are so excited to welcome you as our first users. We appreciate your interest in joining us and would like to
        give you an access before the official release of the platform in Fall 2023. Stay Tuned!
      </Text>
      <Text>Development Team</Text>
    </Html>
  );
};

export default FanEmail;
