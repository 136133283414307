import React from 'react';
import { Button, Layout } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import Fallback from './Fallback';
import logo from '../resources/logo.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import useWindowDimensions from '../hooks/useWindowDimentions';

const { Header } = Layout;

function App() {
  const dimensions = useWindowDimensions();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        className="main-header"
        style={{ fontWeight: 700, paddingInline: 0, boxShadow: 'rgb(68, 71, 71) 0px 0.5px 0px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingInline: 10,
            width: '100%',
            marginBottom: 1
          }}
        >
          <div style={{ display: 'flex', paddingInline: '1rem' }}>
            <Link style={{ flex: '0 0 64px', height: 64, marginRight: '1rem' }} to="/">
              <img style={{ height: 32, marginTop: '1rem' }} src={logo} alt="logo" />
            </Link>
          </div>
          <div>
            <Button
              type="ghost"
              icon={<QuestionCircleOutlined style={{ fontSize: 18 }} />}
              style={{ fontSize: 18, fontWeight: 'bold', height: 'auto' }}
              onClick={() =>
                window.open('https://toppersona.notion.site/FAQ-7b928347876e4541ad1a9f2fadeda0df?pvs=4', '_blank')
              }
            >
              {dimensions.width > 367 ? 'FAQ' : ''}
            </Button>
          </div>
        </div>
      </Header>
      <ErrorBoundary fallback={<Fallback />}>
        <Outlet />
      </ErrorBoundary>
    </Layout>
  );
}

export default App;
