import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';

import './styles/index.css';
import reportWebVitals from './reportWebVitals';

import Router from './router/Router';
import SettingsProvider from './providers/SettingsProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <title>topPersona</title>
          <meta name="description" content="Create your own AI Persona or find one to chat with" />
          <meta
            name="keywords"
            content="AI persona, AI chatting, AI celebrities, chat with celebrity, create AI personality"
          />
        </Helmet>
        <SettingsProvider>
          <Router />
        </SettingsProvider>
      </HelmetProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
