import React, { Dispatch } from 'react';

interface ThemeContext {
  isDarkMode: boolean;
  setIsDarkMode?: Dispatch<React.SetStateAction<boolean>>;
}

const ThemeContext = React.createContext<ThemeContext>({
  isDarkMode: true
});

export default ThemeContext;
