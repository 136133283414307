import React, { useContext, useState } from 'react';
import { Button, Layout, theme, Typography } from 'antd';
import { render } from '@react-email/render';
import ThemeContext from '../../contexts/ThemeContext';
import CreatorContactsModal from './CreatorContactsModal';
import { useContactForm } from '../../hooks/useContactForm';
import UserContactsModal from './UserContactsModal';
import logo from '../../resources/logo_cut.svg';
import useWindowDimensions from '../../hooks/useWindowDimentions';
import FanEmail from './FanEmail';
import TalentEmail from './TalentEmail';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const clamp = (min: number, num: number, max: number) => {
  return num <= min ? min : num >= max ? max : num;
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const Landing: React.FC = () => {
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const { isDarkMode } = useContext(ThemeContext);
  const { sendCreatorInfo, sendUserInfo, sendUserEmail, sendCreatorEmail } = useContactForm();
  const [showCreatorModal, setShowCreatorModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const dimensions = useWindowDimensions();

  const tags = [
    {
      key: 1,
      label: '🎇 TikTok Famous',
      style: { top: 10, left: dimensions.width > 768 ? '4%' : '0%' },
      animationSpeed: 36
    },
    {
      key: 2,
      label: '🎬 Actors',
      style: { top: 80, left: dimensions.width > 768 ? '15%' : '10%' },
      animationSpeed: 32
    },
    {
      key: 3,
      label: '🏈 Sport Icons',
      style: { top: 150, left: dimensions.width > 768 ? '25%' : '20%' },
      animationSpeed: 28
    },
    {
      key: 4,
      label: '👾 Streamers',
      style: { top: 60, left: dimensions.width > 768 ? '47%' : '40%' },
      animationSpeed: 25
    },
    {
      key: 5,
      label: '🎤 K-pop Idols',
      style: { top: 150, right: dimensions.width > 768 ? '25%' : '20%' },
      animationSpeed: 36
    },
    {
      key: 6,
      label: '🎀 Models',
      style: { top: 80, right: dimensions.width > 768 ? '15%' : '10%' },
      animationSpeed: 32
    },
    {
      key: 7,
      label: '💎 Instagram Influencers',
      style: { top: 10, right: dimensions.width > 768 ? '4%' : '0%' },
      animationSpeed: 28
    }
  ];

  const emojis = [
    {
      key: 1,
      label: '⭐️',
      style: { top: dimensions.width > 768 ? 10 : 45, left: dimensions.width > 768 ? '30%' : '27%' },
      animationSpeed: 36
    },
    {
      key: 2,
      label: '😍',
      style: { top: dimensions.width > 768 ? 80 : 105, left: dimensions.width > 768 ? '40%' : '33%' },
      animationSpeed: 30
    },
    {
      key: 3,
      label: '🤩',
      style: { top: dimensions.width > 768 ? 150 : 10, left: dimensions.width > 768 ? '53%' : '40%' },
      animationSpeed: 25
    },
    { key: 4, label: '👀', style: { top: dimensions.width > 768 ? 80 : 105, right: '34%' }, animationSpeed: 36 },
    {
      key: 5,
      label: '❓',
      style: { top: dimensions.width > 768 ? 10 : 45, right: dimensions.width > 768 ? '28%' : '25%' },
      animationSpeed: 30
    }
  ];

  const getFontSize = () => {
    const value = clamp(12, dimensions.width * 0.02, 20) + getRandomInt(-10, 20);
    return clamp(10, value, 40);
  };

  const handleCreatorForm = async (email: string, contacts: string) => {
    setContactLoading(true);
    await sendCreatorInfo(email, contacts).then(() => setContactLoading(false));
    await sendCreatorEmail(email, 'Top Persona AI Submission', render(<TalentEmail />));
  };

  const handleUserForm = async (email: string, suggestions: string) => {
    setContactLoading(true);
    await sendUserInfo(email, suggestions).then(() => setContactLoading(false));
    await sendUserEmail(email, 'Top Persona AI Early Access', render(<FanEmail />));
  };

  return (
    <Content style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        className={`content-bg-container${isDarkMode ? ' dark-theme' : ''}`}
        style={{
          background: colorBgContainer,
          border: 0,
          maxWidth: 'none',
          overflowX: 'hidden'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '50rem',
            width: '100%',
            paddingInline: '1rem'
          }}
        >
          <Typography style={{ textAlign: 'center' }}>
            <Title level={1} className="text-colorful" style={{ fontWeight: 900 }}>
              Create your official AI Persona and become a BFF, Date, or Soulmate with your fans!
            </Title>
            <Paragraph
              style={{ color: isDarkMode ? '#A7AFBB' : '#434b57', opacity: 0.8, fontSize: 26, marginTop: '1em' }}
            >
              Followers can now chat, get calls and pics from your AI Persona. Pretty wild, huh?
            </Paragraph>
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <Button
              className="landing-button"
              type="primary"
              shape="round"
              size="large"
              style={{
                margin: '0.5rem',
                fontSize: 20,
                padding: '12px 24px',
                height: 56,
                backgroundColor: '#61B5FD',
                color: '#282F36',
                fontWeight: 'bold'
              }}
              onClick={() => setShowCreatorModal(true)}
            >
              Create AI Persona
            </Button>
            <Button
              className="landing-button"
              type="primary"
              shape="round"
              size="large"
              style={{
                margin: '0.5rem',
                fontSize: 20,
                padding: '12px 24px',
                height: 56,
                backgroundColor: '#61B5FD',
                color: '#282F36',
                fontWeight: 'bold'
              }}
              onClick={() => setShowUserModal(true)}
            >
              Fan Early Access
            </Button>
          </div>
          <Title level={2} style={{ textAlign: 'center', fontWeight: 800 }}>
            <img style={{ height: 40, verticalAlign: 'sub' }} src={logo} alt="logo" /> Verified Celebrities only!
          </Title>
        </div>
        <div style={{ position: 'relative', width: '100%', height: 250 }}>
          {emojis.map((emoji) => (
            <div
              key={emoji.key}
              className={`home-tag emoji-tag animation-reversed-${emoji.animationSpeed}`}
              style={{ position: 'absolute', ...emoji.style }}
            >
              <div
                style={{
                  padding: '6px 12px',
                  backgroundColor: '#DFE8F7',
                  borderRadius: 18,
                  fontSize: getFontSize()
                }}
              >
                {emoji.label}
              </div>
            </div>
          ))}
          {tags.map((tag) => (
            <div
              key={tag.key}
              className={`home-tag animation-${tag.animationSpeed}`}
              style={{ position: 'absolute', ...tag.style }}
            >
              <div
                style={{
                  padding: '6px 12px',
                  backgroundColor: '#f7eedf',
                  borderRadius: 18
                }}
              >
                {tag.label}
              </div>
            </div>
          ))}
        </div>
      </div>
      <CreatorContactsModal
        open={showCreatorModal}
        loading={contactLoading}
        onSubmit={handleCreatorForm}
        onCancel={() => setShowCreatorModal(false)}
      />
      <UserContactsModal
        open={showUserModal}
        loading={contactLoading}
        onSubmit={handleUserForm}
        onCancel={() => setShowUserModal(false)}
      />
    </Content>
  );
};

export default Landing;
