import React from 'react';
import { Button, Layout, Result } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const NoMatch: React.FC = () => (
  <Layout style={{ height: '100vh' }}>
    <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary">
            <Link to="/">Back Home</Link>
          </Button>
        }
      />
    </Content>
  </Layout>
);

export default NoMatch;
