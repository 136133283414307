import React from 'react';
import { Layout, Result } from 'antd';

const { Content } = Layout;

const Fallback: React.FC = () => (
  <Layout>
    <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Result status="500" title="500" subTitle="Something went wrong" />
    </Content>
  </Layout>
);

export default Fallback;
