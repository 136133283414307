import React, { ReactNode, useEffect, useState } from 'react';
import { ConfigProvider, theme as antTheme } from 'antd';
import ThemeContext from '../contexts/ThemeContext';

interface Props {
  children: ReactNode;
}

const SettingsProvider: React.FC<Props> = (props) => {
  const { children } = props;
  const storageIsDarkMode = localStorage.getItem('isDarkMode');
  const [isDarkMode, setIsDarkMode] = useState(storageIsDarkMode === null ? true : JSON.parse(storageIsDarkMode));
  const themeValue = { isDarkMode, setIsDarkMode };

  useEffect(() => {
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={themeValue}>
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? antTheme.darkAlgorithm : antTheme.defaultAlgorithm,
          token: { colorPrimary: '#61B5FD', fontFamily: 'Nunito, "Open Sans", Roboto, "Open Sans", sans-serif' }
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

export default SettingsProvider;
